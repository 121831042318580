import styles from './styles.module.css';

import moment from 'moment';
import React, {useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchCompanies, getTransactions, setFilter} from './slice';
import Spinner from '../../../components/Spinner';
import CommonInput from '../../../components/CommonInput';

const TransactionsPage = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const transferId = useSearchParams()[0].get('transfer');
    const {loading, companies, list, filters} = useAppSelector((state) => state.adminTransactions);

    const statuses = [{
        value: '', name: t('select.all')
    }, {
        value: 'success', name: t('transaction.status.success')
    }, {
        value: 'cancel', name: t('transaction.status.cancel')
    }, {
        value: 'fail', name: t('transaction.status.fail')
    }];

    const groupedList = useMemo(() => {
        const groups: {[key: string]: ITransaction[]} = {};

        list.forEach(item => {
            const date = moment(item.paymentDate).format('DD MMM YYYY');
            if (!groups[date]) groups[date] = [];
            groups[date].push(item);
        });

        return groups;
    }, [list]);

    const total = useMemo(() =>
            list.reduce((s, i) => s + +i.totalPrice, 0),
        [list]);

    useEffect(() => {
        let f = {...filters};
        if (transferId) {
            f = {transferId, status: undefined, from: undefined, to: undefined};
            dispatch(setFilter(f));
        }

        dispatch(fetchCompanies());
        dispatch(getTransactions(f));
    }, []);

    const handleFormSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        dispatch(getTransactions(filters));
    };

    return <>
        {loading && <Spinner/>}

        <form className={styles.form} onSubmit={handleFormSubmit}>
            <CommonInput
                type={'select'}
                label={t('transaction.status.')}
                labelAbove={true}
                value={filters.status || ''}
                onChange={(e) => dispatch(setFilter({status: e.target.value || undefined}))}
                options={statuses}
            />

            <CommonInput
                type={'select'}
                label={t('transaction.gateway.')}
                labelAbove={true}
                value={filters.gateway || ''}
                onChange={(e) => dispatch(setFilter({gateway: e.target.value || undefined}))}
                options={[
                    {value: '', name: t('select.all')},
                    {value: 'siru', name: t('transaction.gateway.siru')},
                    {value: 'hello', name: t('transaction.gateway.hello')}
                ]}
            />

            <CommonInput
                type={'date'}
                label={t('transaction.from')}
                labelAbove={true}
                value={filters.from || ''}
                onChange={(e) => dispatch(setFilter({from: e.target.value || undefined}))}
            />

            <CommonInput
                type={'date'}
                label={t('transaction.to')}
                labelAbove={true}
                value={filters.to || ''}
                onChange={(e) => dispatch(setFilter({to: e.target.value || undefined}))}
            />

            <CommonInput
                type={'text'}
                label={t('transaction.productFilter')}
                labelAbove={true}
                value={filters.product || ''}
                onChange={e => dispatch(setFilter({product: e.target.value || undefined}))}
            />

            <CommonInput
                type={'select'}
                label={t('transaction.companyFilter')}
                labelAbove={true}
                value={filters.companyId || ''}
                onChange={(e) => dispatch(setFilter({companyId: +e.target.value || undefined}))}
                options={[
                    {value: '', name: t('select.noCompany')},
                    ...companies.map((i) => ({value: i.id, name: i.name})),
                ]}
            />

            <CommonInput
                type={'text'}
                label={t('transaction.clientPhone')}
                labelAbove={true}
                value={filters.clientPhone || ''}
                onChange={e => dispatch(setFilter({clientPhone: e.target.value || undefined}))}
            />

            <CommonInput
                type={'select'}
                label={t('transaction.paid')}
                labelAbove={true}
                value={filters.transfer || ''}
                onChange={(e) => dispatch(setFilter({transfer: e.target.value || undefined}))}
                options={[
                    {value: '', name: t('select.all')},
                    {value: 'true', name: t('select.yes')},
                    {value: 'false', name: t('select.no')}
                ]}
            />

            <CommonInput
                type={'text'}
                label={t('transaction.transfer')}
                labelAbove={true}
                value={filters.transferId || ''}
                onChange={(e) => dispatch(setFilter({transferId: e.target.value || undefined}))}
            />

            <CommonInput
                type={'text'}
                label={t('transaction.orderId')}
                labelAbove={true}
                value={filters.id || ''}
                onChange={(e) => dispatch(setFilter({id: e.target.value || undefined}))}
            />

            <div></div>
            <div></div>

            <div>
                <button type="submit" className="main-button size-normal">{t('button.search')}</button>
            </div>
        </form>

        <table className={styles.table}>
            <thead>
            <tr>
                <th>{t('transaction.time')}</th>
                <th>{t('transaction.id')}</th>
                <th>{t('transaction.status.')}</th>
                <th>{t('transaction.gateway.')}</th>
                <th>{t('transaction.company')}</th>
                <th>{t('transaction.product')}</th>
                <th>{t('transaction.clientPhone')}</th>
                <th className="align-c">{t('transaction.deactivated')}</th>
                <th className="align-r">{t('transaction.amount')}</th>
                <th className="align-c">{t('transaction.paid')}</th>
            </tr>
            </thead>
            {Object.entries(groupedList).map(([date, list]) => <tbody key={date}>
            <tr>
                <th colSpan={8}>{date}</th>
                <th className="align-r">{list.reduce((s, i) => s + +i.totalPrice, 0).toFixed(2)} €</th>
                <th></th>
            </tr>
            {list.map((i, k) => <tr key={k}>
                <td>{moment(i.paymentDate).format('HH:mm')}</td>
                <td>{i.id}</td>
                <td>{t('transaction.status.' + i.status)}</td>
                <td>{t('transaction.gateway.' + i.gateway)}</td>
                <td>{companies.find(c => c.id === i.companyId)?.name}</td>
                <td>{i.productName}<br/>{i.productOwnerPhone}</td>
                <td>{i.phoneNumber}</td>
                <td className="align-c">{i.deactivationOrigin && i.deactivationDate ? moment(i.deactivationDate).format('HH:mm') : '--'}</td>
                <td className="align-r">{i.totalPrice} €</td>
                <td className="align-c">{!i.transferId ? '--' :  <>
                    {moment(i.transferCreated).format('DD MMM YYYY')}
                    <br/>
                    <span title={i.transferId}>{i.transferId.slice(0, 9)}...</span>
                </>}</td>
            </tr>)}
            </tbody>)}
            {!total ? null : <tbody>
            <tr>
                <th colSpan={6}></th>
                <th className="align-c">{t('transaction.total')}</th>
                <th className="align-r">{total.toFixed(2)} €</th>
                <th></th>
            </tr>
            </tbody>}
        </table>
    </>;
};

export default TransactionsPage;